import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DashboardComponent} from './dashboard.component';
import {DashboardRoutingModule} from './dashboard-routing.module';
import {ButtonModule} from "primeng/button";
import {SidebarModule} from "primeng/sidebar";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {CheckboxModule} from "primeng/checkbox";
import {UsersModule} from '../users/users.module';
import {AgenciesModule} from '../agencies/agencies.module';
import {PreferencesModule} from '../preferences/preferences.module';
import {HeaderModule} from "../core/header/header.module";
import {FooterModule} from "../core/footer/footer.module";
import {SideNavigationBarModule} from "../core/sidebar/sidenavigationbar.module";
import {ProgressBarModuleLib} from "../core/progress-bar/progress-bar-module-lib.module";
import {EsocModule} from '../e-soc/esoc.modules';
import {PatientModule} from "../patient/patient.module";
import {AddSupportTicketComponent} from '../add-support-ticket/add-support-ticket.component';
import {FileUploadModule} from "primeng/fileupload";
import { CompaniesModule } from '../companies/companies.module';
import { OverlayBannerModule } from './overlay-banner/overlay-banner.module';
import {TotpSetupModule} from "../totp-setup/totp-setup.module";
import { FormDataModule } from '../form-data/form-data.module';

@NgModule({
  declarations: [
    DashboardComponent,
    AddSupportTicketComponent
  ],
    imports: [
        CommonModule,
        DashboardRoutingModule,
        ButtonModule,
        SidebarModule,
        SideNavigationBarModule,
        FormsModule,
        InputTextModule,
        CheckboxModule,
        UsersModule,
        AgenciesModule,
        TotpSetupModule,
        EsocModule,
        PreferencesModule,
        HeaderModule,
        FooterModule,
        ProgressBarModuleLib,
        PatientModule,
        ReactiveFormsModule,
        FileUploadModule,
        CompaniesModule,
        OverlayBannerModule,
        FormDataModule
    ]
})
export class DashboardModule { }
