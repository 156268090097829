import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'questionText',
})
export class QuestionTextPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }
    // Replace double underscores with single space and ensure text remains uppercase
    return value.replace(/__/g, ' ').toUpperCase();
  }
}
