import { Injectable } from '@angular/core';
import { ServiceClientService } from 'src/app/services/service-client.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Response } from '../../models/user';
import { FormDataObject } from 'src/app/models/form-data';

@Injectable({
  providedIn: 'root',
})
export class FormDataService {
  constructor(private serviceClientService: ServiceClientService) {}

  getFormDataList(payload: any): Observable<Response<FormDataObject[]>> {
    return this.serviceClientService.post(
      environment.endPoints.getFormData,
      payload,
    );
  }

  createOrUpdateFormData(payload: any): Observable<Response<FormDataObject>> {
    return this.serviceClientService.post(
      environment.endPoints.createFormData,
      payload,
    );
  }

  // payload: { id: string }
  deleteFormData(payload: any): Observable<Response<FormDataObject>> {
    return this.serviceClientService.post(
      environment.endPoints.deleteFormData,
      payload,
    );
  }
}
