import { AddressDto, agencyStatusValues, statusValues as agency_status_values, status as agency_status } from "./agency";

export interface Response<T>{
    success: boolean,
    content: {
        totalElements: number;
        totalCompaniesCount: number;
        data: T;
    },
    returnCode: number,
    errorMessage?: string
}

export interface PhoneDto {
    countryCode: string;
    number: string
}

export interface Company {
    id?: string;
    logo: string;
    name: string;
    status: string;
    phoneNumber: PhoneDto;
    fax: PhoneDto;
    email: string;
    address: AddressDto;
    createdAt: Date;
    createdBy: string;
}

export interface CompanyId {
    companyId?: string;
}

export const companyStatusValues = agencyStatusValues

export const statusValues = agency_status_values

export const status = agency_status
export const defaultSelectedCompanyRows = [
    'Company',
    'Email',
    'Phone',
    'Status'
  ]
  export const CompanyyListingRows = [
    {value: 'Company', label: 'Company'},
    {value: 'Email', label: 'Email'},
    {value: 'Phone', label: 'Phone'},
    {value: 'Status', label: 'Status'},
  ];

export const CompanyUserRegistrationType = [
  { value: 'notSet', label: 'Not Set'},
  { value: 'enabled', label: 'Enabled'},
  { value: 'disabled', label: 'Disabled'}
];

export enum CompanyUserRegistrationTypeEnum {
  NotSet = 'notSet',
  Enabled = 'enabled',
  Disabled = 'disabled'
}

export const rotationSetOptions = [
  { value: true, label: 'Enable'},
  { value: false, label: 'Disable'}
];

export const rotationDurationOptions = [
  {
    value: 30,
    label: '30 Days'
  },
  {
    value: 60,
    label: '60 Days'
  },
  {
    value: 90,
    label: '90 Days'
  },
  {
    value: 120,
    label: '120 Days'
  },
  {
    value: 150,
    label: '150 Days'
  },
  {
    value: 180,
    label: '180 Days'
  },
  {
    value: 210,
    label: '210 Days'
  }
]
