import {ANSWER_MAPPING, RoleID} from "../../common/Constants";
import {FormGroup} from "@angular/forms";

export function swapLatitudeAndLongitude(latitude:number,longitude:number) {
  if(latitude < -90 || latitude > 90) {
    let temp = latitude;
    latitude = longitude;
    longitude = temp;
  }

  return {latitude,longitude};
}
export function isMultiAgenciesUserEnabled(userAgenciesRoles) {
  let roles = [];
  userAgenciesRoles.forEach((agency) => {
    roles.push(...agency.roles);
  });
  let unique = roles.filter((role, index,array) => array.indexOf(role) === index);
  const allowedRoles = [RoleID.AgencyAdmin.toString(),RoleID.OfficeWorker.toString(), RoleID.CaseManager.toString()];
  return unique.some(r=> allowedRoles.includes(r));
}

export function getAnswerLabel(ans: string) {
    if (ANSWER_MAPPING[ans]) {
      return ANSWER_MAPPING[ans];
    } else {
      return ans;
    }
  }

  export function isMfaChange(newUserForm: FormGroup) {
    newUserForm.get("userSettings.mfa").valueChanges.subscribe((obj) => {
      if (newUserForm.get("userSettings.mfa_token").value === false && obj === false) return
      if (newUserForm.get("userSettings.mfa_token").value === true && obj === true) {
        newUserForm.get("userSettings").get("mfa_token").setValue(false)
      }
    });

    newUserForm.get("userSettings.mfa_token").valueChanges.subscribe((obj) => {
      if (newUserForm.get("userSettings.mfa").value === false && obj === false) return
      if (newUserForm.get("userSettings.mfa").value === true && obj === true) {
        newUserForm.get("userSettings").get("mfa").setValue(false)
      }
    });
  }

