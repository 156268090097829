import { Agency } from "./agency";

export interface LoginUser {
  email: string;
  password: string;
}

export interface User {
  userExperimentModes?: any;
  id?: string;
  aws_cognito_id?: string;
  username?: string;
  firstName?: string;
  lastName?: string;
  gender?: string;
  phone?: { countryCode: string; number: string };
  fax?: { countryCode: string; number: string };
  status?: string;
  email?: string;
  is_super_admin?: boolean;
  is_IOH_admin?: boolean;
  is_company_admin?: boolean;
  is_ioh_user?: boolean;
  agency_roles?: AgencyRole[];
  picture?: string;
  defaultRole?: string;
  address?: Address;
  password?: string;
  companyId?: string;
  createdAt?: Date;
  createdBy?: string;
  creator?: string;
  currentUserRole?: string;
  userSettings?: UserSettings;
  lastLogin?: Date;
  agent?: string;
  _id?: string;
  permissions?: string[];
  androidVersion?: string;
  desktopVersion?: string;
  platform?: string;
  lastActivity?: Date;
  lastAction?: Date;
  isIOHHelp?: boolean;
  agencyObject?: Agency[];
  changePassword?: Date;
  provider?: string;
  noActivity?: boolean;
  registrationStatus?: string;
  totp_secret?: string;
  aws_totp_code?: string;
  loginWithPCIDenabled?: string;
  workerId?: string;
}

export interface UserSettings {
  logging: boolean;
  loggingView: boolean;
  isMultiAgenciesAllowed: boolean;
  mfa: boolean;
  mfa_token: boolean;
  mfa_verified: boolean;
  experimentMode: number;
  disableUpdatePopup: boolean;
  NONE: boolean;
  NETWORK_FILES_LOGS: boolean;
  ML_RULES: boolean;
  QUIZ_TEMPLATES: boolean;
  QUIZ_AUTOMATOR: boolean;
  CLINICIAN_ROUTE_OPTIMIZATION: boolean;
  HIDE_DIAGNOSIS_POPUP: boolean;
  HIDE_RULES: boolean;
  HIDE_LOOKBACKS: boolean;
  disableOverdueVisitsPopup: boolean;
}

export const userSettingsValues = {
  logging: true,
  loggingView: false,
  isMultiAgenciesAllowed: false,
  mfa: false,
  mfa_token: false,
  experimentMode: 0,
  disableUpdatePopup: false,
  networkFileLogs: false,
  mlRules: false,
  quizTemplates: false,
  quizAutomator: false,
  clinicianRouteOptimization: false,
  hideDiagnosisPopup: false,
  hideRules: false,
  hideLookbacks: false,
  disableOverdueVisitsPopup: false
}

export const userSettingsLabels = [
  // {
  //   label: "Disable Update PopUp", value: "disableUpdatePopup"
  // },
  {
    label: "Accessibility stop logs", value: "networkFileLogs"
  },
  {
    label: "DL Rules", value: "mlRules"
  },
  {
    label: "Quiz form-data", value: "quizTemplates"
  },
  {
    label: "Quiz fill automator", value: "quizAutomator"
  },
  {
    label: "Clinician route opt btn", value: "clinicianRouteOptimization"
  },
  {
    label: "Hide Diagnosis Popup", value: "hideDiagnosisPopup"
  },
  {
    label: "Hide Rules", value: "hideRules"
  },
  {
    label: "Hide Look Backs", value: "hideLookbacks"
  },
  {
    label: "Disable Overdue Visits Popup", value: "disableOverdueVisitsPopup"
  }

]

export const userExperimentModesLabel = [
  {
    label: "None", value: "NONE"
  },
  {
    label: "Accessibility stop logs", value: "NETWORK_FILES_LOGS"
  },
  {
    label: "ML Rules", value: "ML_RULES"
  },
  {
    label: "Quiz form-data", value: "QUIZ_TEMPLATES"
  },
  {
    label: "Quiz fill automator", value: "QUIZ_AUTOMATOR"
  },
  {
    label: "Clinician Rout Optimization", value: "CLINICIAN_ROUTE_OPTIMIZATION"
  },
  {
    label: "Hide Diagnosis Popup", value: "HIDE_DIAGNOSIS_POPUP"
  },
  {
    label: "Hide Rules", value: "HIDE_RULES"
  },
  {
    label: "Hide Look Backs", value: "HIDE_LOOKBACKS"
  }
]

export const userExperimentModes = {
  NONE: false,
  NETWORK_FILES_LOGS: false,
  ML_RULES: false,
  QUIZ_TEMPLATES: false,
  QUIZ_AUTOMATOR: false,
  CLINICIAN_ROUTE_OPTIMIZATION: false,
  HIDE_DIAGNOSIS_POPUP: false,
  HIDE_RULES: false,
  HIDE_LOOKBACKS: false
}

export const userExperimentModesValues = {
  NONE: 0,
  networkFileLogs: 1,//NETWORK_FILES_LOGS: 1,
  mlRules: 2,//ML_RULES: 2,
  quizTemplates:4,//QUIZ_TEMPLATES: 4,
  quizAutomator:8,//QUIZ_AUTOMATOR: 8,
  clinicianRouteOptimization: 16//CLINICIAN_ROUTE_OPTIMIZATION: 16
}

export interface Address {
  state: string;
  country: string;
  city: string;
  line1: string;
  line2?: string;
  postalCode: string;
}

export interface AgencyRole {
  agency: { agencyID: string; agencyName: string };
  roles: string[];
  defaultAgency: boolean;
}

export interface Response<T> {
  success: boolean;
  content?: {
    pagination?: any;
    totalElements?: number;
    totalUsersCount?: number;
    data: T;
  };
  returnCode?: number;
  errorMessage?: string;
  message?: string;
}

export const userStatusValues = [
  { label: "All", value: "" },
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
];

export const bulkUploadTemplateValues = [{ label: "Download Template", value: "Download Template" }];

export const IOHUserRows = [
  { value: "User", label: "User" },
  { value: "Email", label: "Email" },
  { value: "Phone", label: "Phone" },
  { value: "Created By", label: "Created By" },
  { value: "Created At", label: "Created At" },
  { value: "Status", label: "Status" },
  { value: "Last Action", label: "Last Action" },
  { value: "Last Activity", label: "Last Activity" },
];

export const defaultSelectedIOHUserRows = [
  "User",
  "Email",
  "Phone",
  "Agency",
  "Roles",
  "Created By",
  "Created At",
  "Status",
  "Last Action",
  "Last Activity",
];

export const NonIOHUserRows = [
  { value: "Agency", label: "Agency" },
  { value: "User", label: "User" },
  { value: "Email", label: "Email" },
  { value: "Phone", label: "Phone" },
  { value: "WorkerId", label: "Worker ID" },
  { value: "Roles", label: "Roles" },
  { value: "Provider", label: "Provider" },
  { value: "Created By", label: "Created By" },
  { value: "Created At", label: "Created At" },
  { value: "Status", label: "Status" },
  { value: "Last Action", label: "Last Action" },
  { value: "Last Activity", label: "Last Activity" },
];
export const UserFilterDropdown = [
  { value: "Activity Stopped", label: "No Action" },
  // { value: "GPS", label: "GPS" },
  { value: "Floating", label: "Floating" },
  { value: "Push", label: "Push" },
  { value: "Read", label: "Read" },
];

export const UserFilterDropdownPermissions = ["Floating", "Push", "Read"];
export enum UserStatus {
  active = "Active",
  inactive = "Inactive",
}
export const defaultSelectedNonIOHUserRows = [
  "Agency",
  "User",
  "Email",
  "Phone",
  "Roles",
  "Provider",
  "Created By",
  "Created At",
  "Status",
  "Last Logged",
];
