import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from './dashboard.component';
import {AuthGuard} from '../security/auth.guard';
import {UsersComponent} from '../users/ioh-users/users.component';
import {AgenciesComponent} from '../agencies/agencies.component';
import {PreferencesComponent} from '../preferences/preferences.component';
import {AuthChildrenGuard} from "../security/auth.children.guard";
import {
  AllExceptPatientAllowedRoles,
  OnlyAdminAllowedRoles, OnlyAllowedExceptClinicianRoles,
  OnlyCaseManagerClinicianAllowedRoles,
  OnlyClinicianAllowedRoles,
  OnlySuperAdminAllowedRoles,
  RoleID
} from "../common/Constants";
import {NotAllowedComponent} from "../core/not-allowed/not-allowed.component";
import {EsocComponent} from '../e-soc/e-soc/esoc.component';
import {PatientComponent} from "../patient/patient.component";
import {SupportComponent} from "../support/support.component";
import {AddSupportTicketComponent} from '../add-support-ticket/add-support-ticket.component';
import {NonIohUsersComponent} from "../users/non-ioh-users/non-ioh-users.component";
import {UnmatchedVisitsComponent} from "../unmatched-visits/unmatched-visits.component";
import {VisitComponent} from "../visit/visit.component";
import {EpisodeComponent} from "../episode/episode.component";
import {InvoicesComponent} from "../e-soc/invoices/invoices.component";
import {OthersComponent} from "../e-soc/others/others.component";
import { CompaniesComponent } from '../companies/companies.component';
import {FileUploadComponent} from "../file-upload/file-upload.component";
import { FormDataComponent } from '../form-data/form-data.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'ioh-users',
        canActivate: [AuthChildrenGuard],
        data: { roles: OnlySuperAdminAllowedRoles },
        component: UsersComponent
      },
      {
        path: 'users',
        canActivate: [AuthChildrenGuard],
        data: { roles: OnlyAdminAllowedRoles },
        component: NonIohUsersComponent
      },
      {
        path: 'e-docs',
        canActivate: [AuthChildrenGuard],
        data: { roles: OnlyAllowedExceptClinicianRoles },
        component: EsocComponent,
        children: [{
            path: 'invoices',
            canActivate: [AuthChildrenGuard],
            data: { roles: OnlyClinicianAllowedRoles },
            component: InvoicesComponent
          },
          {
          path: 'others',
          canActivate: [AuthChildrenGuard],
          data: { roles: OnlyClinicianAllowedRoles },
          component: OthersComponent
        },
        ]
      },
      {
        path: 'agencies',
        canActivate: [AuthChildrenGuard],
        data: { roles: OnlyAdminAllowedRoles },
        component: AgenciesComponent
      },
      {
        path: 'agencies/:id',
        canActivate: [AuthChildrenGuard],
        data: { roles: OnlyAdminAllowedRoles },
        component: AgenciesComponent
      },
      {
        path: 'support',
        canActivate: [AuthChildrenGuard],
        data: { roles: OnlySuperAdminAllowedRoles },
        component: SupportComponent
      },
      {
        path: 'support-ticket',
        canActivate: [AuthChildrenGuard],
        data: { roles: OnlyCaseManagerClinicianAllowedRoles },
        component: AddSupportTicketComponent
      },
      {
        path: 'upload-file',
        canActivate: [AuthChildrenGuard],
        data: { roles: AllExceptPatientAllowedRoles },
        component: FileUploadComponent
      },

      {
        path: 'not-allowed',
        component:NotAllowedComponent
      },
      {
        path: 'preferences',
        canActivate: [AuthChildrenGuard],
        component: PreferencesComponent
      },
      {
        path: 'episodes',
        canActivate: [AuthChildrenGuard],
        component: EpisodeComponent
      },
      {
        path: 'visits',
        canActivate: [AuthChildrenGuard],
        component: VisitComponent
      },
      /*{
        path: 'episodes',
        canActivate: [AuthChildrenGuard],
        component: EpisodeComponent
      },*/
      {
        path: 'unmatched-visits',
        canActivate: [AuthChildrenGuard],
        component: UnmatchedVisitsComponent
      },
      {
        path: 'p-visits',
        canActivate: [AuthChildrenGuard],
        component: PatientComponent
      },
      {
        path: 'companies',
        canActivate: [AuthChildrenGuard],
        data: { roles: OnlyAdminAllowedRoles },
        component: CompaniesComponent
      },
      {
        path: 'form-data',
        canActivate: [AuthChildrenGuard],
        data: { roles: OnlyAdminAllowedRoles },
        component: FormDataComponent
      },
    ]
  },
  // {
  //   path: 'esoc-verification',
  //   //canActivate: [AuthChildrenGuard],
  //  // data: { roles: OnlyAdminAllowedRoles },
  //   component: EsocVerificationComponent
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
